<template>
  <component
    v-bind:is="comp"
    v-bind="{
      ...(to ? { to } : {}),
    }"
    class="s-menu-item"
  >
    <h4><slot></slot></h4>
    <span class="subtitle" v-if="$slots.subtitle">
      <slot name="subtitle"></slot>
    </span>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false,
    },
  },
  computed: {
    comp() {
      return this.to ? "router-link" : "div";
    },
  },
  name: "SettingsMenuItem",
};
</script>

<style scoped>
.s-menu-item {
  display: flex;
  flex-direction: column;

  padding: 1rem;
  border-radius: 0.5rem;
  background: var(--darkerBackground);
  color: white;
}

.s-menu-item h4 {
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0;
}

.s-menu-item span.subtitle {
  font-size: 0.8rem;
  color: hsla(0, 0%, 100%, 0.5);
}
</style>
